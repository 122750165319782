import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  redirectToHomeMiddleware,
  checkAccessMiddleware,
  setPageTitleMiddleware,
  checkMobileSettingsMiddleware,
  preloaderStart,
  preloaderStop,
} from './middlewares';

//pages
import LoginPage from '../pages/Login';
import RegisterPage from '../pages/Register';
import HomePage from '../pages/Home';
import NewsPage from '../pages/News';
import PollsPage from '../pages/Polls';
import OpenPoll from '../pages/OpenPoll';
import ServicesPage from '../pages/Services';
import AdditionalServicePage from '../pages/AdditionalServicePage';
import ShopPage from '../pages/Shop';
// import ShopMobilePage from "../pages/ShopMobile";
import UserCartPage from '../pages/UserCart';
// import UserCartMobilePage from "../pages/UserCartMobile";
import AnnouncementPage from '../pages/Announcement';
import ProfilePage from '../pages/Profile';
import PaymentPage from '../pages/Payment';
import RequestsPage from '../pages/Requests';
import NewRequestPage from '../pages/NewRequest';
import RequestConfirmationPage from '../pages/RequestConfirmation';
import MetersPage from '../pages/Meters';
import OssInfoPage from '../pages/OssInfo';
import OssPage from '../pages/Oss';
import OssVotePage from '../pages/OssVote';
import OssResultPage from '../pages/OssResult';
//import MeetingBegan from '../pages/MeetingBegan'
import RequestChatPage from '../pages/RequestChatPage';
import MobileApp from '../pages/MobileApp';
import AccessGuide from '../pages/AccessGuide';
import AccessGuideAlt from '../pages/AccessGuideAlt';
import NotFound from '../pages/NotFound';
// import additionalService from "../store/modules/additionalService"; // ?
import PrivilegeClub from '@/pages/PrivilegeClub';
import i18n from '@/plugins/i18n';

// import detectScreenMixin from "@/mixins/detectScreen";

Vue.use(VueRouter);

// let isMobile = detectScreenMixin.methods.isMobile();

const routes = [
  {
    path: '/',
    name: 'IndexRouterPage',
    redirect: 'home',
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
    meta: { title: i18n.t('registration'), layout: 'auth-layout' },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { title: i18n.t('authorization'), layout: 'auth-layout' },
  },
  {
    path: '/openpoll/:code',
    name: 'OpenPoll',
    component: OpenPoll,
    meta: { title: i18n.t('polls'), layout: 'open-poll-layout' },
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    meta: { title: i18n.t('account'), isAuth: true },
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesPage,
    props: true,
    meta: { title: i18n.t('special_offers'), isAuth: true },
  },
  {
    path: '/additional_service/:id',
    name: 'AdditionalService',
    component: AdditionalServicePage,
    props: true,
    meta: { title: i18n.t('additional_service'), isAuth: true },
  },
  {
    path: '/services/:id/:type/:id_RequestType?/:AdditionalServiceId',
    name: 'Shop',
    // component: isMobile ? ShopMobilePage : ShopPage,
    component: ShopPage,
    props: true,
    meta: { title: i18n.t('shop'), isAuth: true, layout: 'services-layout' },
  },
  {
    path: '/usercart/:id/:type/:id_RequestType?/:AdditionalServiceId',
    name: 'UserCart',
    // component: isMobile ? UserCartMobilePage : UserCartPage,
    component: UserCartPage,
    props: true,
    meta: { title: i18n.t('user_cart'), isAuth: true, layout: 'services-layout' },
  },
  {
    path: '/requestconfirmation/',
    name: 'RequestConfirmation',
    component: RequestConfirmationPage,
    meta: { title: i18n.t('confirmation_order'), isAuth: true },
  },
  {
    path: '/news/:id',
    name: 'News',
    component: NewsPage,
    props: true,
    meta: { title: i18n.t('news'), isAuth: true },
  },
  {
    path: '/polls/:subpage?/:id?',
    name: 'Polls',
    component: PollsPage,
    meta: { title: i18n.t('polls'), isAuth: true, layout: 'polls-layout' },
  },
  {
    path: '/announcement/:id',
    name: 'Announcement',
    component: AnnouncementPage,
    meta: { title: i18n.t('notification'), isAuth: true },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
    meta: { title: i18n.t('profile'), isAuth: true },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: PaymentPage,
    meta: { title: i18n.t('payment'), isAuth: true },
  },
  {
    path: '/requests/new',
    name: 'NewRequest',
    component: NewRequestPage,
    meta: { title: i18n.t('new_request'), isAuth: true },
  },
  {
    path: '/requests/:requestID?',
    name: 'Requests',
    component: RequestsPage,
    props: true,
    meta: { title: i18n.t('requests'), isAuth: true, layout: 'requests-layout' },
  },
  {
    path: '/meters',
    name: 'Meters',
    component: MetersPage,
    meta: { title: i18n.t('readings'), isAuth: true },
  },
  {
    path: '/oss',
    name: 'Oss',
    component: OssPage,
    meta: { title: i18n.t('owners_meetings'), isAuth: true },
  },
  {
    path: '/oss_info/:id',
    name: 'OssInfo',
    component: OssInfoPage,
    meta: { title: i18n.t('owners_meetings'), isAuth: true },
  },
  {
    path: '/oss_vote/:id',
    name: 'OssVote',
    component: OssVotePage,
    meta: { title: i18n.t('owners_meetings') + '. ' + i18n.t('voting'), isAuth: true },
  },
  {
    path: '/oss_result/:id',
    name: 'OssResult',
    component: OssResultPage,
    meta: { title: i18n.t('owners_meetings') + '. ' + i18n.t('result'), isAuth: true },
  },

  /*{
    path: '/oss',
    name: 'MeetingBegan',
    component: MeetingBegan,
    meta: { title: 'Собрание собственников' }
  },*/
  {
    path: '/rc/:code',
    name: 'RequestChatPage',
    component: RequestChatPage,
    meta: { title: 'Чат по заявке', layout: 'empty-layout' },
  },
  {
    path: '/mp',
    name: 'MobileApp',
    component: MobileApp,
    meta: { title: i18n.t('download_mobile_application'), layout: 'empty-layout' },
  },
  {
    path: '/ag',
    name: 'AccessGuide',
    component: AccessGuide,
    meta: { title: i18n.t('access_guide'), layout: 'empty-layout' },
  },
  {
    path: '/triniti',
    name: 'AccessGuideAlt',
    component: AccessGuideAlt,
    meta: { title: i18n.t('access_guide'), layout: 'empty-layout' },
  },

  // {
  //   path: '/mobile-app',
  //   name: 'MobileApp',
  //   component: MobileApp,
  //   meta: { title: 'Скачать мобильное приложение', layout: 'empty-layout' },
  // },

  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: { title: i18n.t('error'), layout: 'empty-layout' },
  },

  {
    path: '/privilege-club',
    name: 'PrivilegeClub',
    component: PrivilegeClub,
    meta: {
      title: i18n.t('privilege_club_for_new_residents'),
      isAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(checkAccessMiddleware);
router.beforeEach(redirectToHomeMiddleware);
router.beforeEach(checkMobileSettingsMiddleware);
router.beforeEach(setPageTitleMiddleware);
router.beforeEach(preloaderStart);
router.afterEach(preloaderStop);

export default router;
