<template>
  <div>
    <iframe
      style="width: 100%; height: 100vh; margin: 0; padding: 0; border: none;"
      src="https://inter.sm-center.ru/#/triniti"
    />
  </div>
</template>

<script>
export default {
  name: 'AccessGuideAlt',
}
</script>

<style
  lang="scss"
  scoped
>
.empty__container {
  max-width: inherit !important;
  padding-inline: inherit !important;
}
</style>